:root {
  --solana-gradient: linear-gradient(to top right, #9945ff, #64a8f2, #12f195) 1;
}


.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.box-gradient-border {
  border: 2px solid transparent;
  border-image: var(--solana-gradient);
}
